import React from "react"
import Layout from "../../components/layout"
import PostEntry from "../../components/PostEntry"
import SEO from "../../components/seo"
import Nav from "../../components/nav"

import styled from "styled-components"

const Section = styled.div`
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  @media screen and (max-width: 600px) {
    height: 80%;
  }
`

const Main = styled.div`
  height: auto;
`

const Projects = ({ pageContext }) => {
  const { nodes } = pageContext

  return (
    <Layout>
      <SEO
        title="Projects"
        description="Projects posts"
        keywords={[`projects`]}
      />
      <Nav></Nav>
      <Section>
        <Main>
           {nodes && nodes.map(post => <PostEntry key={post.postId} post={post}/>)}
        </Main> 
      </Section>
    </Layout>
  )
}

export default Projects