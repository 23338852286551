import { Link } from "gatsby"
import React from "react"

import HomeIcon from "../assets/home.svg"

import styled from 'styled-components'

const MainNav = styled.div`
    height: 80px;
    position: fixed;
    width: 100px;
    padding: 0 4rem;
    display: grid;
    justify-items: end;
    top: 0;
    right: 2rem;
`

const Home = styled.div`
        width: 40px;
        height: 40px;
        margin-top: 2em;
`

class Nav extends React.Component {
    render () {
    return (
        <MainNav>
            <Home>
                <Link to="/"><HomeIcon /></Link>
            </Home>
        </MainNav>
      )
    }
}
  
  export default Nav