import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"

const List = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  display: flex
`
const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    float: left;
    display: block;
    margin-bottom: 1rem;
}
`

const Art = styled.span`
  font-family: minion-pro-display, serif;
`
const Date = styled.span`
  font-weight: 700;
  margin: 0 0.4rem;
`

const Title = styled.span`
  font-weight: 500;
`

const StyledLink = styled(props => <Link {...props} />)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 2.2rem;
`

const PostEntry = ({ post }) => {

  const { uri, title, pageBuilder } = post

  return (
    <List>
        <StyledLink to={`${uri}`}> 
          <Container>
            <Art>{pageBuilder.art}</Art>
            <Date><strong>{pageBuilder.date}</strong></Date>
            <Title>{title}</Title>
          </Container>
        </StyledLink>
    </List>
  )
}

export default PostEntry